.franchise-bg {
    background-image: url(../img/franchise_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-color: #2e2e2e;
}
.franchise-select-box {
    /* width: 100px; */
    height: 100%;
    /* background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%); */
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}
.icon-explor-select {
    width: 38px;
    height: 38px;
    background-image: url('../img/icon_explor_select@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explor-title {
	height: 22px;
	width: 510px;
	background-image: url(../img/explor_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}

.explor-start {
    background: rgba(0,0,0,0.7);
    box-shadow: 0px 16px 18px 0px rgba(0,0,0,0.5);
    border-radius: 6px;
    border: 1px solid rgba(255,255,255,0.13);
    backdrop-filter: blur(5.435px);
}

.explor-start-img {
    width: 150px;
    height: 215px;
    background-image: url('../img/explor-bg@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-start-title {
    font-size: 32px;
    color: #FFFFFF;
    font-family: HelveticaNeue;
    line-height: 38px;
}
.explor-start-desc {
    width: 530px;
    font-size: 16px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(255,255,255,0.6);
    text-align: center;
    line-height: 22px;
}
.explor-start-button {
    background: #565656;
    border-radius: 6px;
    border: 1px solid #646464;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    font-family: PingFangSC-Regular, PingFang SC;
    width: 143px;
    height: 41px;
    margin-top: 16px;
}
.explor-download {
    width: 38px;
    height: 38px;
    background: rgba(102,102,102,0.3);
    border-radius: 4px;
    backdrop-filter: blur(5.435px);
}
.icon-explor-download {
    width: 18px;
    height: 18px;
    background-image: url('../img/icon_explor_download@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-arrow-box {
    margin: 0 10px;
    background: rgba(102,102,102,0.3);
    border-radius: 4px;
    backdrop-filter: blur(5.435px);
    width: 23px;
    padding: 6px 0;
}
.explor-arrow {
    width: 10px;
    height: 8px;
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-arrow-up {
    background-image: url('../img/explor_arrow_uncheck@2x.png');
    margin-bottom: 8px;
    transform: rotate(0deg);
}
.explor-arrow-up-active {
    background-image: url('../img/explor_arrow_check@2x.png');
    transform: rotate(180deg);
}
.explor-arrow-down {
    background-image: url('../img/explor_arrow_uncheck@2x.png');
    transform: rotate(180deg);
}
.explor-arrow-down-active {
    background-image: url('../img/explor_arrow_check@2x.png');
    transform: rotate(0deg);
}
.explor-group-item {
    background: rgba(255,255,255,0.3);
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.36);
    backdrop-filter: blur(5.435px);
    padding: 0 6px;
}
.explor-group-delete {
    width: 10px;
    height: 10px;
    background-image: url('../img/explor_group_delete@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 6px;
}
.explor-group-item-arrow {
    width: 10px;
    height: 8px;
    background-image: url('../img/explor_group_item_arrow@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 6px;
}
.explor-group-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.explor-group-search-title {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.5);
    line-height: 18px;
    min-width: 260px;
}
.explor-group-search-detail {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 26px;
    min-width: 260px;
}
.explor-group-search-select-box {
    width: 20px;
}
.explor-page-button {
    width: 30px;
    height: 30px;
    background: rgba(102,102,102,0.3);
    border-radius: 4px;
    backdrop-filter: blur(5.435px);
    margin-right: 10px;
}
.explor-page-button-avalible {
    background: rgba(255,255,255,0.2);
    backdrop-filter: blur(13.59px);
}
.explor-arrow-right {
    width: 8px;
    height: 10px;
    background-image: url('../img/explor_arrow_right@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-arrow-left {
    width: 8px;
    height: 10px;
    background-image: url('../img/explor_arrow_left@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-page-info {
    font-size: 14px;
    font-weight: normal;
    color: #989898;
}
.explor-page-info > span {
    color: #FF773A;
}
.explor-pop-button {
    width: 206px;
    height: 30px;
    background: rgba(255,255,255,0.2);
    border-radius: 4px;
    backdrop-filter: blur(13.59px);
    margin-left: 20px;
}

.explor-attribute-box {
    width: 884px;
    height: 640px;
    background: rgba(0,0,0,0.95);
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 10px;
    padding: 20px 30px 10px 30px;
}
.explor-attribute-head {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    font-family: HelveticaNeue-Bold, HelveticaNeue;
    margin-bottom: 24px;
}
.explor-attribute-close {
    width: 14px;
    height: 14px;
    background-image: url('../img/explor_attribute_close@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-attribute-step-box {
    height: 48px;
    background: rgba(223,223,223,0);
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.2);
    backdrop-filter: blur(13.59px);
    margin-bottom: 12px;
}
.explor-attribute-step {
    margin: 12px;
    background: rgba(255,255,255,0.16);
    border-radius: 4px;
    backdrop-filter: blur(13.59px);
    width: 114px;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
}
.explor-attribute-step-active {
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(210,212,217,0.22);
    backdrop-filter: none;
    color: #000;
}
.explor-attribute-line {
    width: 20px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../img/explor_attribute_line@2x.png');
}
.explor-attribute-line-active {
    background-image: url('../img/explor_attribute_line_active@2x.png');
}
.explor-attribute-content-box {
    border-bottom: 1px solid rgba(154, 157, 167, 0.5);
    margin-bottom: 10px;
}
.explor-attribute-condition-box {
    margin-bottom: 40px;
}
.explor-attribute-condition-view {
    font-size: 12px;
    color: rgba(255,255,255,0.6);
    margin-bottom: 16px;
}
.explor-attribute-condition-title {
    font-family: Helvetica;
    margin-right: 8px;
}
.explor-attribute-condition-item {
    font-size: 12px;
    color: rgba(255,255,255,0.6);
    margin-bottom: 10px;
}
.explor-dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #FF773A;
}
.explor-item-name {
    font-family: Helvetica;
    margin: 0 8px 0 6px;
}
.explor-item-icon {
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
}
.explor-item-icon-add {
    background-image: url('../img/explor_add@2x.png');
}
.explor-item-icon-remove {
    background-image: url('../img/explor_remove@2x.png');
}
.explor-attribute-step2 {
    margin-top: 30px;
}
.step2-title {
    margin-bottom: 20px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
}
.explor-attribute-step3 {
    margin-top: 30px;
}
.step3-title {
    margin-bottom: 24px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
}
.step3-input-name {
    font-size: 12px;
    color: #fff;
}
.explor-attribute-button {
    width: 206px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgba(255,255,255,0.2);
    border-radius: 6px;
    backdrop-filter: blur(13.59px);
    font-size: 12px;
    color: #fff;
    margin-left: 20px;
}
.explor-attribute-select-box {
    height: 28px;
    background: rgba(255,255,255,0.16);
    border-radius: 4px;
    backdrop-filter: blur(13.59px);
    overflow: hidden;
}
.explor-attribute-select-box-active {
    border: 1px solid rgba(255,115,52,0.5);
}
.explor-attribute-select-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #fff;
    font-family: Helvetica;
}
.step1-arrow {
    width: 9px;
    height: 6px;
    margin-left: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../img/explor_step1_arrow_down@2x.png');
}
.step1-arrow-active {
    background-image: url('../img/explor_step1_arrow_up@2x.png');
}
.explor-attribute-list-box {
    min-width: 200px;
    height: 100%;
    overflow-x: hidden;
}
.explor-list-items {
    font-size: 12px;
    font-family: Helvetica;
    color: #fff;
    line-height: 14px;
}
.explor-checked-list {
    border-left: 1px solid rgba(154, 157, 167, 0.5);
    padding-left: 15px;
}
.explor-select-title {
    font-size: 12px;
    font-family: Helvetica;
    color: #fff;
}
.explor-drop-active {
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
    border-radius: 4px;
    background: rgba(255, 115, 52, 0.16);
}
.explor-label {
    font-size: 12px;
    font-family: Helvetica;
    color: #FFFFFF;
}
.explor-step2-tags {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    background: #000000;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.2);
    height: 32px;
    width: 186px;
}
.explor-step2-input {
    height: 32px;
    width: 70px;
}
.step2-icon {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
}
.step2-icon-add {
    background-image: url('../img/explor_step2_add@2x.png');
}
.step2-icon-remove {
    background-image: url('../img/explor_step2_remove@2x.png');
}
.step2-tag-icon-box {
    padding: 3px 5px;
    background: rgba(102,102,102,0.3);
    border-radius: 3px;
    backdrop-filter: blur(4.004736842105262px);
}
.step2-tag-icon {
    width: 7px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: contain;
}
.step2-tag-up {
    background-image: url('../img/explor_step2_up@2x.png');
}
.step2-tag-up-active {
    background-image: url('../img/explor_step2_up_active@2x.png');
}
.step2-tag-down {
    background-image: url('../img/explor_step2_down@2x.png');
}
.step2-tag-down-active {
    background-image: url('../img/explor_step2_down_active@2x.png');
}
.step2-tag-item {
    width: 76px;
    height: 24px;
    background: rgba(255,255,255,0.3);
    border-radius: 2px;
    backdrop-filter: blur(5.435px);
    margin-right: 4px;
}
.step2-tag-name {
    padding-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.step2-del {
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../img/explor_attribute_close@2x.png');
    margin-right: 2px;
}
.step2-or {
    font-size: 12px;
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #FF773A;
}
.explor-select-item {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF773A;
    padding-left: 14px;
    line-height: 16px;
}
.step-2-desc {
    font-size: 12px;
    font-family: Helvetica;
    color: rgba(255,255,255,0.5);
    margin-bottom: 20px;
}