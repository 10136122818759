.basic-title {
    font-size: 12px;
    font-weight: 500;
    color: #FF773A;
    line-height: 18px;
    margin: 10px 0;
}
.basic-wrapper {
    margin-right: 10px;
}
.basic-box {
    /* margin-top: 10px; */
    height: 170px;
    overflow-x: hidden;
}
.basic-item {
    padding: 6px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(255,255,255,0.5);
}
.basic-value {
    
    color: #FFFFFF;
}