.eva-title {
    width: 88px;
    height: 20px;
    background: #343333;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 8px;
    margin: 10px 0;
}
.eva-box {
    height: 188px;
    overflow-x: hidden;
}
.eva-num {
    border-radius: 2px;
    line-height: 16px;
    font-size: 12px;
    height: 16px;
    margin-left: 6px;
    width: 36px;
    text-align: center;
}
.eva-item-box {
    padding: 6px 0;
}
.ad-num {
    background: rgba(89, 179, 54, 0.2);
    color: #59B336;
}
.de-num {
    background: rgba(255, 119, 58, 0.2);
    color: #FF773A;
}