.custom-select-box {
	height: 28px;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 6px;
	border: 1px solid rgba(255, 255, 255, 0.13);
}
.custom-select-error {
	border: 1px solid rgba(255, 115, 52, 0.5);
}
.custom-select-icon {
	width: 12px;
	height: 8px;
	background-image: url(../../assets/img/icon-select-custom@2x.png);
	background-size: contain;
	background-repeat: no-repeat;
	margin: 0 10px;
}
.custom-select-placeholder {
	font-size: 12px;
	font-family: Helvetica;
	color: rgba(255, 255, 255, 0.6);
}
.custom-select-value {
	font-size: 12px;
	font-family: Helvetica-Bold, Helvetica;
	font-weight: bold;
	color: #FFFFFF;
}
.custom-select-content {
	background: rgba(223, 223, 223, 0.06);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 10px;
	min-width: 200px;
	min-height: 100px;
}
.custom-select-button {
	height: 30px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 6px;
	font-size: 12px;
	font-family: Helvetica;
	color: #FFFFFF;
}
.custom-select-list {
	border-bottom: 1px solid rgba(154, 157, 167, 0.5);
}
.custom-select-list-box {
	max-height: 400px;
	overflow-y: auto;
}
.select-button-width {
	width: 100px;
}