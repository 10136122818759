.pagination-box .MuiPaginationItem-root {
    height: 30px;
    min-width: 30px;
}
.pagination-table-box {
    /* background: #ffffff; */
    box-shadow: 0px 0px 8px 0px rgba(51, 71, 91, 0.1);
    border-radius: 2px;
    overflow: hidden;
}
.pagination-table-box .MuiPaper-root {
    background: #1a1a1a;
}
