.login-wrapper {
    background: #191919;
}
.login-text-info {
    width: 32%;
    height: 100%;
    background-image: url(../../assets/img/login_text_bg@2x.png);
    background-repeat: no-repeat;
	background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.login-logo {
    margin-top: 269px;
    /* margin-top: 30%; */
    /* margin-left: 14%; */
    /* width: 435px; */
    width: 157px;
    height: 86px;
    /* height: 87px; */
    background-image: url(../../assets/img/login_nike_logo@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 100px;
}
.login-text {
    font-size: 38px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    /* color: #ff773a; */
    color: #FD5000;
}
.login-text-sub {
    font-size: 34px;
    font-family: Helvetica;
    color: #ffffff;
    margin-bottom: 60px;
}
.login-logo-text-box {
    /* margin-left: 14%; */
}
.login-logo-text-title {
    font-size: 38px;
    /* font-size: 34px; */
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.login-logo-text-desc {
    font-size: 16px;
    font-family: Helvetica;
    color: #ffffff;
    line-height: 20px;
}
.login-error-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #e13e3e;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.login-error-icon {
    width: 12px;
    height: 12px;
    background-image: url(../../assets/img/login_error_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4px;
}
.remember-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.forget-text {
    font-size: 12px;
    font-family: Helvetica;
    /* color: #ff773a; */
    color: #FD5000;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    text-decoration: underline;
}
.remember-icon {
    width: 14px;
    height: 14px;
    background-image: url(../../assets/img/remember_frame@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 2px;
    margin-top: -3px;
}
.remember-yes {
    width: 8px;
    height: 8px;
    background-image: url(../../assets/img/remember_yes@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.modify-logo {
    width: 238px;
    height: 263px;
    background-image: url(../../assets/img/modify_logo@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.modify-label {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.modify-title {
    font-size: 44px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    top: 260px;
}
.reset-bg {
    width: 32%;
    height: 100%;
    background-image: url(../../assets/img/reset_bg@2x.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.reset-text {
    font-size: 34px;
    font-family: Helvetica;
    color: #ffffff;
}

.login-dialog-content-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/img/login_dialog_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.login-dialog-reset-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/img/reset_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.img-box {
    width: 74px;
    height: 32px;
    margin-top: 8px;
}
