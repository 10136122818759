.download-logo {
    margin-top: 90px;
    width: 244px;
    height: 230px;
    background-image: url(../img/download_logo@2x.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    font-size: 44px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: -60px;
    box-sizing: border-box;
}
.download-button-item {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.36);
    padding: 0 8px;
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
}
.input-category {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 6px;
}
.download-time-box {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    height: 32px;
    padding: 0 10px;
}
.download-table-box {
    /* width: 548px; */
    /* min-width: 548px; */
    height: 82px;
    border-radius: 6px;
    border: 1px solid rgba(111, 111, 111, 0.8);
    overflow: hidden;
}
.download-table-box:hover {
    border: 1px solid rgba(0,253,36,0.4);   
}
.progress-icon {
    width: 20px;
    height: 20px;
    background-image: url(../img/progress_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-file-icon {
    width: 21px;
    height: 24px;
    background-image: url(../img/download_file@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-file-calculation {
    width: 21px;
    height: 24px;
    background-image: url(../img/icon-download-caculation@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-file-failed {
    width: 21px;
    height: 24px;
    background-image: url(../img/icon-download-failed@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.file-title {
    font-size: 14px;
    font-family: HelveticaNeue;
    font-weight: 600;
    color: #ffffff;
    line-height: 18px;
}
.download-file-time {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
}
.download-button-icon {
    width: 12px;
    height: 12px;
    background-image: url(../img/download_file_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.back-button-icon {
    width: 12px;
    height: 12px;
    background-image: url(../img/icon_back@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-button {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.download-caution-icon {
    width: 28px;
    height: 28px;
    background-image: url(../img/download_caution_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-details-button {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    width: 160px;
    height: 36px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.36);
}
.back-button {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    width: 80px;
    height: 36px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.36);
}
.download-generate-icon {
    width: 28px;
    height: 28px;
    background-image: url(../img/download_generate_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.upload-logo {
    margin-top: 80px;
    width: 488px;
    height: 270px;
    background-image: url(../img/upload_logo@2x.png);
    background-repeat: no-repeat;
    background-position: top center;
	background-size: 50%;
	margin-bottom: -90px;
}
.upload-box {
    width: 620px;
    height: 210px;
}
.upload-box .MuiDropzoneArea-root {
    background-color: rgba(255, 255, 255, 0.14);
    border-radius: 6px;
    border: 2px dashed rgba(176, 176, 176, 0.8);
    min-height: 210px;
}
.upload-box .MuiDropzoneArea-textContainer {
    position: relative;
}
.upload-box .MuiDropzoneArea-text {
    position: absolute;
    top: 100px;
    left: 170px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.upload-box .MuiDropzoneArea-icon {
    margin-top: 60px;
    color: #fff;
    width: 40px;
    height: 40px;
}
.upload-content-box {
    background-color: rgba(255, 255, 255, 0.14);
    border-radius: 6px;
    border: 2px dashed rgba(176, 176, 176, 0.8);
    min-height: 210px;
}
.upload-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 50px;
}
.upload-file-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.upload-succeed-icon {
    width: 40px;
    height: 40px;
    background-image: url(../img/upload_succeed_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.tab-border {
	border-bottom: 1px solid rgba(255,255,255,0.3);	
	font-size: 19px;
	font-family: Helvetica;
	color: #FFFFFF;
	box-sizing: border-box;
}
.tab-border-active {
	font-family: Helvetica-Bold, Helvetica;
	font-weight: bold;
	border-bottom: 4px solid #fff;
}
.template-download {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}
.template-icon {
	width: 12px;
    height: 12px;
    background-image: url(../img/icon_template_download@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.template-hover:hover {
	color: #FF773A;
	cursor: pointer;
}
.upload-code-box .MuiDropzoneArea-root {
	background: rgba(255, 255, 255, 0.14);
	border: 2px dashed rgba(176, 176, 176, 0.8);
	height: 72px;
	min-height: auto;
}
.upload-code-box .MuiTypography-h5 {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.upload-code-box .MuiDropzoneArea-textContainer {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
}
.upload-code-box .MuiDropzoneArea-icon {
	width: 26px;
	height: 26px;
	margin-right: 20px;
	fill: #fff;
}
.upload-code-file {
	background: rgba(255, 255, 255, 0.14);
	border: 2px dashed rgba(176, 176, 176, 0.8);
	height: 72px;
}
.file-desc {
	font-size: 20px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}
.upload-title-desc {
	font-size: 44px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    box-sizing: border-box;
    text-align: center;
}
.upload-buttons {
	width: 176px;
	height: 42px;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 6px;
	border: 1px solid rgba(255, 255, 255, 0.36);
	font-size: 14px;
	font-family: Helvetica;
	color: #FFFFFF;
}
.upload-check-title {
	font-size: 44px;
	font-family: Helvetica-Bold, Helvetica;
	font-weight: bold;
	color: #FFFFFF;
}
.upload-check-icon {
	width: 20px;
    height: 20px;
    background-image: url(../img/icon_upload_check@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.check-data-box {
	margin: 24px 0;
}
.check-data-content {
	background: rgba(255, 255, 255, 0.14);
	border-radius: 6px;
}
.check-data-right {
	background: rgba(255, 255, 255, 0.14);
	border-radius: 0px 10px 10px 0px;
	width: 20px;
}
.check-data-left {
	background: rgba(255, 255, 255, 0.14);
	border-radius: 10px 0px 0px 10px;
	width: 20px;
}
.check-data-content-title {
	font-size: 16px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
}
.column-box {
	width: 48%;
	margin-right: 2%;
	height: 80px;
	border-radius: 6px;
}
.column-desc {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}
.column-desc-value {
	font-size: 12px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
}
.column-color {
	color: #fff;
	background: rgba(255, 255, 255, 0.1);
}
.column-color-select {
	color: #000000;
	background: rgba(255, 255, 255, 0.8);
}
.column-applied {
	width: 49px;
	height: 18px;
	background: #FFFFFF;
	border-radius: 4px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #676767;
}
.column-unselect-icon {
	width: 20px;
	height: 20px;
	background: url(../img/column_unselect@2x.png);
	background-size: contain;
	background-repeat: no-repeat;
}
.column-select-icon {
	width: 20px;
	height: 20px;
	background: url(../img/column_select@2x.png);
	background-size: contain;
	background-repeat: no-repeat;
}
.divide-line {
	margin-top: 16px;
	border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.database-list-title {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}
.upload-confirm-title {
	font-size: 14px;
	font-family: Helvetica-Bold, Helvetica;
	font-weight: bold;
	color: #000000;
}
.upload-button-item {
	width: 144px;
	height: 28px;
	background: rgba(55, 55, 55, 0.2);
	border-radius: 6px;
	font-size: 12px;
	font-family: Helvetica;
	color: #000000;
}
.upload-confirm-column-item {
	width: 504px;
}
.column-item-title {
	font-size: 14px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #000000;
}
.column-confirm-box {
	width: 100%;
	height: 80px;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.8);
}
.column-confirm-color {
	color: #000;
	background: rgba(255, 255, 255, 0.8);
}
.group-download-desc {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255,255,255,0.5);
    padding-bottom: 40px;
    text-align: center;
}