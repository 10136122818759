html {
    width: 100%;
    height: 100%;
}
* {
    margin: 0;
    padding: 0;
}
body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #2e2e2e;
    line-height: 1.15;
    color: #fff;
    display: flex;
    overflow-x: auto;
    font-size: 14px;
    flex-direction: column;
    font-family: Helvetica, MicrosoftYaHeiSemibold, Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    height: 100%;
}
.hidden {
    overflow: hidden;
}
.hidden-y {
    overflow-y: hidden;
}
.hidden-x {
    overflow-x: hidden;
}
.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.left {
    left: 0;
}
.top {
    top: 0;
}
.right {
    right: 0;
}
.bottom {
    bottom: 0;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.overflow-y {
    overflow-y: auto;
}
.overflow-auto {
    overflow: auto;
}
.flex {
    display: flex;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-nowrap {
	flex-wrap: nowrap;
}
.flex-column {
    flex-direction: column;
}
.full-height {
    height: 100%;
}
.full-width {
    width: 100%;
}
.flex-1 {
    flex: 1;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-12 {
    font-size: 12px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-18 {
    font-size: 18px;
}
.font-30 {
    font-size: 30px;
}
.font-bold {
    font-weight: bold;
    font-family: Helvetica-Bold, Helvetica;
}
.p4 {
    padding: 4px;
}
.p8 {
    padding: 8px;
}
.p10 {
    padding: 10px;
}
.p12 {
    padding: 12px;
}
.p14 {
    padding: 14px;
}
.p15 {
    padding: 15px;
}
.p16 {
    padding: 16px;
}
.p18 {
    padding: 18px;
}
.p20 {
    padding: 20px;
}
.py2 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.py4 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.py6 {
    padding-top: 6px;
    padding-bottom: 6px;
}
.py8 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.py10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py12 {
	padding-top: 12px;
	padding-bottom: 12px;
}
.py14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py16 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.py20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py35 {
    padding-top: 35px;
    padding-bottom: 35px;
}
.py40 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.px5 {
    padding-left: 5px;
    padding-right: 5px;
}
.px8 {
    padding-left: 8px;
    padding-right: 8px;
}
.px14 {
    padding-left: 14px;
    padding-right: 14px;
}
.px15 {
    padding-left: 15px;
    padding-right: 15px;
}
.px6 {
    padding-left: 6px;
    padding-right: 6px;
}
.px8 {
    padding-left: 8px;
    padding-right: 8px;
}
.px10 {
    padding-left: 10px;
    padding-right: 10px;
}
.px18 {
    padding-left: 18px;
    padding-right: 18px;
}
.px20 {
    padding-left: 20px;
    padding-right: 20px;
}
.px24 {
	padding-left: 24px;
	padding-right: 24px;
}
.px26 {
	padding-left: 26px;
	padding-right: 26px;
}
.px30 {
    padding-left: 30px;
    padding-right: 30px;
}
.px32 {
    padding-left: 32px;
    padding-right: 32px;
}
.px46 {
    padding-left: 46px;
    padding-right: 46px;
}
.px50 {
    padding-left: 50px;
    padding-right: 50px;
}
.px60 {
    padding-left: 60px;
    padding-right: 60px;
}
.pl4 {
    padding-left: 4px;
}
.pl6 {
    padding-left: 6px;
}
.pl8 {
    padding-left: 8px;
}
.pl10 {
    padding-left: 10px;
}
.pl20 {
    padding-left: 20px;
}
.pl26 {
    padding-left: 26px;
}
.pl30 {
    padding-left: 30px;
}
.pr6 {
    padding-right: 6px;
}
.pr10 {
    padding-right: 10px;
}
.pr40 {
    padding-right: 40px;
}
.pt5 {
    padding-top: 5px;
}
.pt6 {
    padding-top: 6px;
}
.pt8 {
    padding-top: 8px;
}
.pt10 {
    padding-top: 10px;
}
.pt14 {
    padding-top: 14px;
}
.pt15 {
    padding-top: 15px;
}
.pt20 {
    padding-top: 20px;
}
.pt26 {
	padding-top: 26px;
}
.pt30 {
    padding-top: 30px;
}
.pt40 {
    padding-top: 40px;
}
.pb8 {
    padding-bottom: 8px;
}
.pb10 {
    padding-bottom: 10px;
}
.pb14 {
    padding-bottom: 14px;
}
.pb15 {
    padding-bottom: 15px;
}
.pb20 {
    padding-bottom: 20px;
}
.pb30 {
    padding-bottom: 30px;
}
.m20 {
    margin: 20px;
}
.my4 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.my10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.my14 {
    margin-top: 14px;
    margin-bottom: 14px;
}
.my20 {
	margin-top: 20px;
	margin-bottom: 20px;
}
.mx20 {
    margin-left: 20px;
    margin-right: 20px;
}
.mr4 {
    margin-right: 4px;
}
.mr6 {
    margin-right: 6px;
}
.mr8 {
    margin-right: 8px;
}
.mr10 {
    margin-right: 10px;
}
.mr14 {
    margin-right: 14px;
}
.mr16 {
    margin-right: 16px;
}
.mr18 {
    margin-right: 18px;
}
.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}
.mr34 {
    margin-right: 34px;
}
.mr40 {
    margin-right: 40px;
}
.mr60 {
    margin-right: 60px;
}
.mr70 {
    margin-right: 70px;
}
.mt2 {
    margin-top: 2px;
}
.mt4 {
    margin-top: 4px;
}
.mt8 {
	margin-top: 8px;
}
.mt10 {
    margin-top: 10px;
}
.mt14 {
    margin-top: 14px;
}
.mt16 {
    margin-top: 16px;
}
.mt20 {
    margin-top: 20px;
}
.mt24 {
    margin-top: 24px;
}
.mt30 {
    margin-top: 30px;
}
.mt50 {
    margin-top: 50px;
}
.mt90 {
    margin-top: 90px;
}
.ml4 {
    margin-left: 4px;
}
.ml6 {
    margin-left: 6px;
}
.ml8 {
	margin-left: 8px;
}
.ml10 {
    margin-left: 10px;
}
.ml20 {
    margin-left: 20px;
}
.ml30 {
    margin-left: 30px;
}
.mb4 {
    margin-bottom: 4px;
}
.mb6 {
    margin-bottom: 6px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb12 {
    margin-bottom: 12px;
}
.mb14 {
    margin-bottom: 14px;
}
.mb15 {
    margin-bottom: 15px;
}
.mb16 {
    margin-bottom: 16px;
}
.mb18 {
    margin-bottom: 18px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb26 {
    margin-bottom: 26px;
}
.mb30 {
    margin-bottom: 30px;
}
.mb34 {
    margin-bottom: 34px;
}
.mb40 {
    margin-bottom: 40px;
}
.mb60 {
    margin-bottom: 60px;
}
.mb90 {
    margin-bottom: 90px;
}
.pointer-disable {
    cursor: not-allowed;
}
.pointer {
    cursor: pointer;
}
.hover {
    cursor: pointer;
}
.hover:hover {
    opacity: 0.8;
}
.weight500 {
    font-weight: 500;
}
.bgwhite {
    background: #fff;
}
.bgwhiteOpacity {
    background: rgba(255, 255, 255, 0.1);
}
.border-box {
    box-sizing: border-box;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.no-wrap {
    white-space: nowrap;
}
.break-all {
    word-break: break-all;
}
.keep-all {
    word-break: keep-all;
}

/* 滚动条整体宽度 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
/* 滚动条滑槽样式 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}
/* 滚动条样式 */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
}
::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.5);
}
/* 浏览器失焦的样式 */
::-webkit-scrollbar-thumb:window-inactive {
    /* background: rgba(255, 0, 0, 0.4); */
}

.dialog-content-box {
    padding: 24px;
    background: rgba(255, 255, 255, 0.8);
}
.dialog-content-title {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
}
.dialog-content-desc {
    font-size: 12px;
    font-family: Helvetica;
    color: #000000;
}
.dialog-operator-item {
    height: 28px;
    background: rgba(55, 55, 55, 0.2);
    border-radius: 6px;
    font-size: 12px;
    font-family: Helvetica;
    color: #000000;
}

.icon-wrap {
    width: 45px;
    height: 45px;
}
