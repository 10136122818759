.menu {
    width: 226px;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.39);
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    height: 870px;
    box-sizing: border-box;
    overflow: hidden;
}
.logo-box {
    /* padding: 34px 0; */
    /* margin: 0 18px; */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
}
.logo {
    background-image: url(../img/nike_logo@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 108px;
}
.account {
    padding: 28px 0;
    margin: 0 18px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.nav-icon {
    width: 18px;
    height: 18px;
    /* background: #fff; */
    background-repeat: no-repeat;
    background-size: contain;
}
.avator {
    margin-right: 10px;
    width: 42px;
    height: 42px;
    background: #fff;
    border-radius: 100%;
}
.nav-button-group {
    margin: 20px 18px;
    height: 56px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}
.switch-item {
    font-family: Helvetica;
    color: #ffffff;
    font-weight: normal;
}
.selected-item {
    background: #fff;
    border-radius: 12px;
    margin-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 2px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000;
}
.card-box {
    /* background: #45387e; */
    box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
}
.card-bg {
    background: rgba(0, 0, 0, 0.7);
    /* border-radius: 10px; */
    filter: blur(4px);
    z-index: -1;
    left: 0;
    top: 0;
}

.title {
    font-size: 28px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 34px;
    background: linear-gradient(180deg, #ffffff 0%, #2e2e2e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.title-box {
	height: 34px;
}

.icon-info {
    background-image: url(../img/icon_info@2x.png);
}
.icon-download {
    background-image: url(../img/icon_download@2x.png);
}
.icon-notification {
    background-image: url(../img/icon_notification@2x.png);
}
.icon-calendar {
    background-image: url(../img/calendar@2x.png);
}
.calendar-item {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4px;
}
.calendar-box {
    background: rgba(0, 0, 0, 0.67);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    padding-top: 4px;
    padding-bottom: 4px;
}

/* new */
.menu-list {
    padding: 0 10px;
}
.nav-item-active.MuiListItem-root {
    background: #4c4d54;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
    border-radius: 4px;
}
.nav-item-active.MuiListItem-root:hover {
    background: #4c4d54;
}
.nav-item-active-2.MuiListItem-root {
    background: #4c4d54;
    border-radius: 4px 4px 0 0;
}
.nav-item-active-2.MuiListItem-root:hover {
    background: #4c4d54;
}
.nav-subItem-active.MuiListItem-root {
    background: #4c4d54;
}
.nav-subItem-active.MuiListItem-root:hover {
    background: #4c4d54;
}
.nav-subItem-active-2.MuiListItem-root {
    background: #4c4d54;
    border-radius: 0 0 4px 4px;
}
.nav-subItem-active-2.MuiListItem-root:hover {
    background: #4c4d54;
}
.nav-text .MuiTypography-body1 {
    color: #fff;
    font-weight: normal;
    font-family: Helvetica;
}
.nav-text .MuiTypography-body1:hover {
    color: #fff;
}
.nav-text-active .MuiTypography-body1 {
    color: #fff;
    font-weight: bold;
    font-family: Helvetica-Bold, Helvetica;
}
.nav-sub-text .MuiTypography-body1 {
    color: #fff;
    font-weight: normal;
    font-family: Helvetica;
}
.nav-sub-text .MuiTypography-body1:hover {
    color: #fff;
}
.nav-sub-text-active .MuiTypography-body1 {
    color: #fff;
    font-weight: bold;
    font-family: Helvetica-Bold, Helvetica;
}
.icon-wrapper {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon-box {
    margin-left: 6px;
}
.icon-wrapper-active {
    background-color: #fff;
    padding: 4px 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-left: -10px;
    margin-right: 6px;
}
.icon-overview {
    background-image: url(../img/overview@2x.png);
}
.icon-overview-active {
    background-image: url(../img/overview_active@2x.png);
}
.icon-sub-category {
    background-image: url(../img/category@2x.png);
}
.icon-sub-category-active {
    background-image: url(../img/category_active@2x.png);
}
.icon-brand {
    background-image: url(../img/brand@2x.png);
}
.icon-brand-active {
    background-image: url(../img/brand_active@2x.png);
}
.icon-franchise {
    background-image: url(../img/franchise@2x.png);
}
.icon-franchise-active {
    background-image: url(../img/franchise_active@2x.png);
}
.icon-gender {
    background-image: url(../img/gender@2x.png);
}
.icon-gender-active {
    background-image: url(../img/gender_active@2x.png);
}
.icon-sku {
    background-image: url(../img/sku@2x.png);
}
.icon-sku-active {
    background-image: url(../img/sku_active@2x.png);
}
.icon-explor {
    background-image: url(../img/sku@2x.png);
}
.icon-explor-active {
    background-image: url(../img/sku_active@2x.png);
}
.icon-manage {
    background-image: url(../img/manage@2x.png);
}
.icon-manage-active {
    background-image: url(../img/manage_active@2x.png);
}
.tab-line {
    width: 30px;
    height: 4px;
    border-radius: 3px;
    border: none;
}
.tab-line-active {
    border: 1px solid #ffffff;
}
.legend-item {
    margin-left: 6px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    width: 56px;
    height: 22px;
    box-sizing: border-box;
}

.selected-legend {
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid #ffffff;
}
.bar-legend {
    width: 20px;
    height: 8px;
    border-radius: 2px;
    margin-right: 6px;
}
.line-legend {
    width: 20px;
    height: 4px;
    border-radius: 2px;
    margin-right: 6px;
}
.user-info-item {
    width: 118px;
    height: 28px;
    background: rgba(0, 0, 0, 0.66);
    border-radius: 6px;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.dot {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 2px;
}
.info-box {
    background: rgba(255, 255, 255, 0.12);
    height: 72px;
    box-sizing: border-box;
    padding: 12px 10px;
}
.brand-percent {
    font-size: 12px;
    font-family: Helvetica;
}
.up-percent {
    color: #4d9b4d;
}
.down-percent {
    color: #ff5f60;
}
.select-item-box {
    height: 54px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
}
.brand-analysis-time {
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
    width: 86px;
    height: 100%;
}
.brand-analysis-time-active {
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
}
.select-brand-item {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.brand-calendar-item {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: contain;
}
.brand-chart-title-box {
    padding: 18px 22px;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.brand-select-box {
    width: 80px;
    height: 100%;
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}

.brand-chart-ppi-box {
    width: 64px;
    height: 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.info-item-border {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.info-item-name {
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* max-width: 80px; */
}
.info-item-name-tooltip {
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
}
.info-item-value {
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
}
.nike-sku-legend-item {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #fff;
}
.legend-dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #fff;
    margin-right: 5px;
}
.nike-sku-visualization {
    background: rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    border: 1px solid rgb(42, 42, 42);
}
.sku-legend-group {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.tooltip-title {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
}
.slider-thumb {
	width: 18px;
	height: 10px;
	background-image: url(../img/icon_slider@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}