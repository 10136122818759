.sku-bg {
    background-image: url(../img/sku_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: top right;
    background-color: #2e2e2e;
}
.sku-bg-filter {
    filter: blur(10px);
    background-image: url(../img/sku_bg@2x.png);
    background-repeat: no-repeat;
    background-position: 100% -25%;
    background-size: 80%;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 0;
}
.sku-item-box {
    height: 54px;
}
.sku-select-box {
    width: 110px;
    height: 100%;
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}
.selected-item-wrapper {
    background: rgba(255, 255, 255, 0.06);
    border-radius: 4px;
    background-image: url(../img/sku_overview_bg@2x.png);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 70%;
}
.sku-name {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.sku-img-wrapper {
    height: 50px;
}
.sku-dot {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}
.sku-tooltip-text {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.sku-card {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 4px;
}
.card-bottom-box {
    background: rgba(255, 255, 255, 0.12);
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.card-bottom-box-nobg {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.compare-value {
    font-size: 22px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
}
.sku-title {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    word-break: break-all;
	/* min-height: 40px; */
	height: 130px;
}
.sku-title-number {
    font-size: 60px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.8;
    left: 0;
    top: -10px;
    z-index: -1;
}
.sku-legend {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    opacity: 0.8;
}
.sku-price {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}

.top-sku-bg-filter {
    filter: blur(10px);
    background-image: url(../img/sku_bg@2x.png);
    background-repeat: no-repeat;
    background-position: 95% -1%;
    background-size: 37%;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 44px;
    overflow: hidden;
}
.img-pos {
    right: -40px;
    transition: all 0.3s ease-in-out;
}
.sku-hover-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: skewX(-40deg);
    background: rgba(255, 255, 255, 0.1);
    left: 130%;
    top: 0;
}
.sku-card:hover .img-pos {
    right: 0px;
    transition: all 0.3s ease-in-out;
}
.sku-card:hover .sku-hover-bg {
    left: 70%;
    transition: all 0.3s ease-in-out;
}
.launch-sku-select-box {
    height: 38px;
    width: 100%;
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}
.sku-semi-check {
    width: 12px;
    height: 12px;
    background-image: url(../img/icon_semi_check@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.sku-overview-title {
	height: 22px;
	width: 480px;
	background-image: url(../img/sku_overview_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-top-total-title {
	height: 22px;
	width: 390px;
	background-image: url(../img/sku_top_total_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-top-male-title {
	height: 22px;
	width: 490px;
	background-image: url(../img/sku_top_male_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-top-female-title {
	height: 22px;
	width: 520px;
	background-image: url(../img/sku_top_female_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-top-kid-title {
	height: 22px;
	width: 488px;
	background-image: url(../img/sku_top_kid_title.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-launch-title {
	height: 22px;
	width: 700px;
	background-image: url(../img/sku_launch_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.attribute-title {
	height: 22px;
	width: 540px;
	background-image: url(../img/attribute_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}