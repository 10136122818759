.select-type {
    margin-right: 13px;
    background: #fff;
    color: #000;
    min-width: 100px;
    height: 20px;
    border-radius: 14px;
    padding: 0 10px;
}

.select-type .MuiInputBase-root fieldset {
    border: none;
}
.select-type .MuiInputBase-input {
    padding: 0;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #181818;
}
.select-type .MuiInput-underline:before {
    border: none;
}
.select-type .MuiInput-underline:after {
    border: none;
}
.drop-title {
    font-size: 12px;
    font-family: Helvetica;
    font-weight: normal;
}

/* new brand analysis */
.brand-analysis-bg {
    background-image: url(../img/brand_analysis_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-color: #2e2e2e;
}
.gender-analysis-bg {
    background-image: url(../img/gender_bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    background-color: #2e2e2e;
}

.gender-select-box {
    width: 100px;
    height: 100%;
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}
.brand-title {
	height: 22px;
	width: 460px;
	background-image: url(../img/brand_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.gender-title {
	height: 22px;
	width: 560px;
	background-image: url(../img/gender_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}