.right-button-switch {
    height: 30px;
    border-radius: 16px;
    padding: 2px;
    background-image: linear-gradient(135deg, rgba(164, 164, 164, 1), rgba(42, 42, 42, 1));
}
.button-switch-box {
    background: #1d1d1d;
    border-radius: 16px;
    padding: 0 4px;
}
.title-share {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.8;
}
.share-border {
    border-right: 1px solid #cfcfcf;
}
.treemap-right-button {
    width: 80px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
}
.button-percent {
    font-size: 14px;
    color: #ffffff;
}
.button-number {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
}
.divid {
    height: 4px;
    background: rgba(223, 223, 223, 0.06);
    filter: blur(6px);
}
.divid-left {
    background: #000;
    filter: blur(0px);
}
.treemap-right-button-selected {
    width: 90px;
    /* border-radius: 4px 0 0 4px; */
    margin-right: -1px;
    z-index: 2;
    background: rgba(216, 216, 216, 0.1);
    background-image: url(../img/color_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-right: none;
    overflow: hidden;
    /* border-right: 1px solid #202020; */
}

.title-total {
    font-size: 20px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.bg-img {
    background-image: url(../img/treemap_bg@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.right-legend {
    padding-top: 14px;
    padding-bottom: 14px;
}
.right-title {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.select-box {
    background: rgba(255, 255, 255, 0.19);
    color: #000;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    height: 20px;
    border-radius: 14px;
    padding: 0 10px;
}
.brand-icon {
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/overview_icon@2x.png);
    margin-right: 4px;
}
.date-picker {
    background: rgba(0, 0, 0, 0.35);
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    padding: 0px 4px;
    border-radius: 4px;
    margin-bottom: 2px;
}

.overview-bg {
    background-image: url(../img/overview_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-color: #2e2e2e;
}
.performance-box {
    background: #45387e;
    background-image: url(../img/demand_bg@2x.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 36%;
}
.demand-title-box {
    height: 10%;
}
.demand-content-box {
    background: rgba(0, 0, 0, 0.97);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
}
.demand-tab {
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
}
.demand-tab-active {
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.demand-chart-title-box {
    padding: 16px 20px 0 20px;
}
.time-box {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 18px;
    padding: 4px;
}
.time-month {
    width: 28px;
    height: 28px;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 100%;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.time-year {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #fff;
    padding: 5px 0;
}
.demand-chart-title-number {
    font-size: 40px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    margin-right: 4px;
}
.demand-chart-symbol {
    font-size: 22px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 6px;
    padding-left: 6px;
}
.demand-chart-icon {
    font-size: 14px;
    color: #ff4647;
    margin-right: 2px;
}
.demand-chart-icon-text {
    font-size: 12px;
    font-family: Helvetica;
    color: rgba(255, 255, 255, 0.79);
    margin-top: 4px;
}
.ftw-color {
    background: linear-gradient(270deg, #ff7334 0%, #ffab64 100%);
}
.app-color {
    background: linear-gradient(270deg, #4b6acf 0%, #8d72ff 100%);
}
.brand-share-legend {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    margin-right: 2px;
}
.barnd-share-legend-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #fff;
}
.brand-share-tab {
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    font-size: 14px;
    width: 52px;
    height: 20px;
    box-sizing: border-box;
}
.brand-share-tab-active {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #ffffff;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
}
.overview-ppi-bg {
    right: 0;
    bottom: 0;
    filter: blur(6px);
    background-image: url(../img/overview_bg@2x.png);
    background-repeat: no-repeat;
    background-position: 4% 90%;
    background-size: 106%;
}
.overview-ppi-chart {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
}
.treemap-title-box {
    height: 18%;
    padding-top: 10px;
}
.treemap-tab-box {
    width: 10%;
}
.treemap-brand-box {
    background: rgba(216, 216, 216, 0.1);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.brand-icon-box {
    width: 46px;
    height: 46px;
    border-radius: 6px;
}
.brand-total {
    background: linear-gradient(180deg, #9988eb 0%, #506bd3 100%);
}
.brand-nike {
    background: linear-gradient(180deg, #ffaf6c 0%, #ff773a 100%);
}
.brand-jordan {
    background: linear-gradient(180deg, #ddbf00 0%, #b98b00 100%);
}
.brand-converse {
    background: linear-gradient(180deg, #70c961 0%, #3d9832 100%);
}
.treemap-brand-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 70%;
    height: 70%;
    background-position: center;
}
.treemap-total {
    background-image: url(../img/treemap_total@2x.png);
}
.treemap-nike {
    background-image: url(../img/treemap_nike@2x.png);
}
.treemap-jordan {
    background-image: url(../img/treemap_jordan@2x.png);
}
.treemap-converse {
    background-image: url(../img/treemap_converse@2x.png);
}
.treemap-brand-title {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
}
.treemap-brand-content {
    font-size: 20px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.treemap-brand-text {
    font-size: 20px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.treemap-chart-box {
    width: 47%;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
}
.treemap-line-chart-box {
    background: rgba(216, 216, 216, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
}
.treemap-line-chart-title {
    padding-top: 12px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.treemap-line-chart-first {
    border-radius: 0 6px 6px 6px;
}
.treemap-line-chart-last {
    border-radius: 6px 6px 6px 0;
}
.modify-bg {
    right: 0;
    top: 0;
    filter: blur(6px);
    background-image: url(../img/overview_bg@2x.png);
    background-repeat: no-repeat;
    background-position: 98% 3%;
    background-size: 48%;
}
.line-dot-legend-box {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    margin-right: 6px;
}
.line-dot-legend {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    box-sizing: border-box;
}
.line-dot-legend-box2 {
    width: 20px;
    height: 4px;
    border-radius: 2px;
    margin-right: 6px;
}
.line-dot-legend2 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-sizing: border-box;
}
.dot-ftw-color {
    background: #ff7334;
    border: 3px solid #131313;
    width: 16px;
    height: 16px;
}
.dot-app-color {
    background: #506bd3;
    border: 3px solid #131313;
    width: 16px;
    height: 16px;
}
.price-tier-name {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.brand-color {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 2px;
}
.price-tier-brandclean {
    min-width: 90px;
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
    max-width: 90px;
}
.price-tier-demand {
    min-width: 70px;
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
}
.price-tier-brandshare {
    min-width: 40px;
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
}
.legend-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.visualization-legend .legend-item {
    padding: 2px 5px;
    margin-left: 6px;
}
.just-do-it-bg {
	width: 240px;
	height: 100%;
	background-image: url(../img/just_do_it_bg@2x.png);
	background-repeat: no-repeat;
	background-size: cover;
	margin-right: -16px;
}
.brand-price-tier {
	height: 22px;
	width: 420px;
	background-image: url(../img/overview_title_price_tier@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.market-performance {
	height: 22px;
	width: 640px;
	background-image: url(../img/overview_title_performance@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}