
.sku-ppi-export-bg {
    background-image: url(../img/sku_ppi_export_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-color: #2e2e2e;
}
.sku-ppi-export-title {
	height: 20px;
	width: 218px;
    margin-bottom: 10px;
	background-image: url(../img/sku-ppi-export-title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.sku-ppi-export-box {
    background: rgba(0,0,0,0.67);
    box-shadow: 0px 16px 18px 0px rgba(0,0,0,0.5);
    border-radius: 16px;
    border: 1px solid rgba(200,200,200,0.31);
    background-image: url(../../assets/img/sku_ppi_export_title_left_bg@2x.png), url(../../assets/img/sku_ppi_export_title_right_bg@2x.png);
    background-repeat: no-repeat no-repeat;
    background-size: 229px 103px, 165px 76px;
    background-position: 169px 86px, right 27px top 90px;
    
}
.sku-ppi-export-box-detail {
    background: url(../img/sku_ppi_export_detail_bg@2x.png) no-repeat, rgba(0,0,0,0.67);
    box-shadow: 0px 16px 18px 0px rgba(0,0,0,0.5);
    border-radius: 16px;
    border: 1px solid rgba(200,200,200,0.31);
    background-position: right top;
    background-size: 400px 120px;
    /* background-image: url(../../assets/img/sku_ppi_export_title_left_bg@2x.png), url(../../assets/img/sku_ppi_export_title_right_bg@2x.png);
    background-repeat: no-repeat no-repeat;
    background-size: 229px 103px, 165px 76px;
    background-position: 169px 86px, right 27px top 90px; */
}
.sku-ppi-export-box-detail-time {
    width: 620px;
    margin-top: 24px;
}
.sku-ppi-export-box-title {
    margin-top: 48px;
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
}
.sku-ppi-export-icon {
    width: 14px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/sku_ppi_export_title_icon@2x.png);
    background-position: center;
    margin-right: 6px;
    margin-left: -20px;
}
.sku-ppi-export-title-desc {
    margin-top: 15px;
    font-family: HelveticaNeue;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.sku-ppi-export-upload-box {
    height: 334px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(200, 200, 200, 0.31);
    margin-bottom: 20px;
    background-image: url(../img/sku_ppi_export_upload_box_bg@2x.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    padding-left: 40px;
    padding-top: 40px;
}
.input-category {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 6px;
}
.sku-ppi-export-box-detail-reset {
    height: 30px;
    background: #FD5000;
    border-radius: 6px;
    width: 90px;
    text-align: center;
    line-height: 30px;
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #FFFFFF;
}
.sku-ppi-export-upload-contain {
    width: 620px;
    height: 114px;
    background: rgba(255,255,255,0.1);
    border-radius: 6px;
    border: 2px dashed rgba(176,176,176,0.8);
}
.sku-ppi-export-upload-contain .upload-code-box .MuiDropzoneArea-root {
	/* background: rgba(255, 255, 255, 0.14);
	border: 2px dashed rgba(176, 176, 176, 0.8); */
    background: transparent;
    border: none;
	height: 114px;
	min-height: auto;
}
.sku-ppi-export-upload-contain .upload-code-box .MuiTypography-h5 {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.sku-ppi-export-upload-contain .upload-code-box .MuiDropzoneArea-textContainer {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
    width: 100%;
    height: 100%;
}
.sku-ppi-export-upload-contain .upload-code-box .MuiDropzoneArea-icon {
	width: 26px;
	height: 26px;
	margin-right: 20px;
	fill: #fff;
}
.template-download {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 48px;
}
.back-button {
    width: 90px;
    height: 30px;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: bold;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 30px;
    margin-bottom: 8px;
}
.back-button-icon {
    width: 16px;
    height: 16px;
    background-image: url(../img/back_button_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.progress-icon {
    width: 20px;
    height: 20px;
    background-image: url(../img/progress_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-button-icon {
    width: 12px;
    height: 12px;
    background-image: url(../img/download_file_icon@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.download-table-box {
    /* width: 548px; */
    /* min-width: 548px; */
    height: 82px;
    border-radius: 6px;
    border: 1px solid rgba(111, 111, 111, 0.16);
    overflow: hidden;
}
.download-table-box:hover {
    border: 1px solid rgba(0,253,36,0.4);   
}
.download-details-button {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    width: 160px;
    height: 36px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.36);
}
.download-file-icon {
    width: 21px;
    height: 24px;
    background-image: url(../img/upload_file@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.file-title {
    font-size: 14px;
    font-family: HelveticaNeue;
    font-weight: 600;
    color: #ffffff;
    line-height: 18px;
}
.upload-file-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.sku-ppi-export-icon-remove {
    width: 14px;
    height: 14px;
    background-image: url(../img/sku_ppi_export_icon_remove@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: 24px;
}