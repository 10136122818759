.dewu-menu {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #fff;
}
.dewu-menu-title {
    padding: 10px 10px 10px 0;
    cursor: pointer;
}
.dewu-menu-title-icon {
    width: 14px;
    height: 14px;
}
.dewu-menu-item {
    padding: 4px 8px;
}
.dewu-menu-item-dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #fff;
    margin-right: 5px;
}
.dewu-menu-item-transition {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
