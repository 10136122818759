.drop-content {
    background: rgba(223, 223, 223, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    min-width: 100px;
    min-height: 100px;
}
.footer-button {
    width: 144px;
    height: 30px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.select-icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/select_icon.png);
    background-position: center;
    margin: 0 6px;
}
.select-icon-model {
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/select_icon_model.png);
    background-position: center;
    /* margin: 0 6px; */
}
.select-icon-gender {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/select_icon_gender.png);
    background-position: center;
    /* margin: 0 6px; */
}
.select-arrow {
    width: 10px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/select_icon_arrow.png);
    background-position: center;
}
.select-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #989898;
}
.select-value {
    color: #ff7334;
    padding-left: 4px;
}
.drop-items {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    min-height: 30px;
    margin-right: 4px;
}
.drop-active {
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
}
.drop-sub-menu {
    border-left: 1px solid rgba(154, 157, 167, 0.5);
    padding-left: 12px;
    margin-left: 8px;
}
.drop-sub-item {
    color: #ff7334;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    min-height: 15px;
    line-height: 16px;
}
.footer-box {
    border-top: 1px solid rgba(154, 157, 167, 0.5);
    padding-top: 10px;
}
.select-content {
    border-top: 1px solid rgba(154, 157, 167, 0.5);
}
.search-content-item {
    min-width: 100px;
    white-space: nowrap;
}
.search-content-description {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    opacity: 0.5;
    min-width: 200px;
    white-space: nowrap;
}
.drop-item-box {
    min-width: 300px;
}
.drop-item-box-fix {
    min-width: 200px;
}
.attributes-list {
    border-bottom: 1px solid rgba(154, 157, 167, 0.5);
    border-top: 1px solid rgba(154, 157, 167, 0.5);
}
.attribute-item {
    width: 108px;
    height: 28px;
    border-radius: 6px;
    border: 1px solid #979797;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
}
.attribute-menu-item {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    /* margin-top: 10px; */
    margin-bottom: 10px;
    padding: 4px 8px;
}
.attribute-item-active {
    background: rgba(255, 115, 52, 0.16);
    border-radius: 4px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
}
.attribute-subItem-active {
    border-radius: 4px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
}
.attribute-subItem-active {
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
}
.attribute-right-box {
    border-left: 1px solid rgba(154, 157, 167, 0.5);
    margin-left: 8px;
}
.attribute-icon {
    width: 12px;
    height: 12px;
    background-image: url(../../assets/img/attribute_remove@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.attribute-selected {
    background: #fff;
    color: #000;
}
.attribute-active {
    color: #ff773a;
    border: 1px solid #ff773a;
    background: #fff;
}
.ppi-icon {
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/img/ppi_icon@2x.png);
    background-position: center;
    margin-right: 4px;
}
.ppi-tab-box {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(154, 157, 167, 0.49);
}
.ppi-tab {
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    padding: 6px 0;
}
.ppi-tab-active {
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(210, 212, 217, 0.22);
    border-radius: 6px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
}
.ppi-menu-item {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 4px 8px;
}
.ppi-menu-item-new {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 4px 8px;
    min-width: 180px;
}
.ppi-item-active {
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ff7334;
}
.tab-item {
    width: 98px;
    height: 28px;
    border-radius: 6px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
}
.tab-item-active {
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(210, 212, 217, 0.22);
    color: #000000;
}
.date-wrapper {
    height: 26px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.icon-check-box {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4px;
}
.icon-check {
    background-image: url(../../assets/img/icon_check.png);
}
.icon-uncheck {
    background-image: url(../../assets/img/icon_uncheck@2x.png);
}
.icon-single-check {
    background-image: url(../../assets/img/icon_single_check@2x.png);
}
.icon-single-uncheck {
    background-image: url(../../assets/img/icon_single_uncheck@2x.png);
}
