.franchise-bg {
    background-image: url(../img/franchise_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-color: #2e2e2e;
}
.franchise-select-box {
    /* width: 100px; */
    height: 100%;
    /* background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%); */
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #0c0c0e;
}
.timeline-box {
    height: 42px;
    box-sizing: border-box;
    padding: 0 14px;
}
.time-tab-box {
    width: 214px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    box-sizing: border-box;
    height: 100%;
}
.lca-tab {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    height: 30px;
    box-sizing: border-box;
}
.lca-tab-active {
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
}
.start-pause-button {
    width: 42px;
    height: 42px;
    background: linear-gradient(180deg, #d2d2d2 0%, #ffffff 100%);
    border-radius: 4px;
}
.timeline-dot {
    width: 14px;
    height: 14px;
    background: linear-gradient(180deg, #ffaf6c 0%, #ff773a 100%);
    border-radius: 100%;
}
.timeline-text {
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.timeline-text-pos {
    position: relative;
    left: -15px;
}
.timeline-text-pos-last {
    left: -30px;
}
.franchise-overview-title {
	height: 22px;
	width: 510px;
	background-image: url(../img/franchise_overview_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}
.franchise-lca-title {
	height: 22px;
	width: 780px;
	background-image: url(../img/franchise_lca_title@2x.png);
	background-repeat: no-repeat;
	background-size: contain;
}

/* 弹窗 */
.pop-dialog .MuiBackdrop-root {
    opacity: 0 !important;
}
.pop-dialog .MuiPaper-root {
    background-color: transparent;
    /* background-color: rgba(0,0,0,0.5); */
    /* background-color: rgba(223,223,223,0.1); */
}

.pop-box {
    /* width: 832px; */
    width: 574px;
    /* height: 550px; */
    border-radius: 11px;
    background: #0C0C0C;
    border: 1px solid rgba(255,255,255,0.2);
    /* backdrop-filter: blur(13.59px); */
    font-size: 12px;
}
.pop-box-lg {
    width: 832px;
}
.pop-head {
    width: 100%;
    height: 48px;
    background: #202122;
    border-radius: 11px 11px 0px 0px;
    /* backdrop-filter: blur(13.59px); */
    padding: 0 20px;
    color: rgba(255,255,255,0.8);
    font-weight: 500;
}
.pop-head .head-left {
    padding-left: 10px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 14px;
}
.pop-button-groups {
    width: 120px;
    height: 24px;
    background: rgba(0,0,0,0.7);
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.13);
    backdrop-filter: blur(5.435px);
    margin-right: 10px;
    line-height: 24px;
}
.pop-head-close {
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    width: 53px;
    height: 24px;
    background: rgba(255,255,255,0.2);
    border-radius: 4px;
    backdrop-filter: blur(13.59px);
    cursor: pointer;
}
.pop-button-item {
    text-align: center;
    cursor: pointer;
}
.pop-button-item.active {
    background: linear-gradient(180deg, #D2D2D2 0%, #FFFFFF 100%);
    border-radius: 4px;
    color: #0C0C0E;
}
.pop-content {
    color: #B6B6B6;
}
.pop-content-left-top-box {
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(154, 157, 167, 0.5);
}
.pop-content-left-box {
    padding: 10px 20px;
}
.pop-content-right-box {
    padding: 12px 8px;
    width: 280px;
}
.pop-content-right-title {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
}
.pop-content-left-down-title {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
}
.pop-content-logo {
    width: 48px;
    background: rgba(255,119,58,0.1);
    border-radius: 4px;
    border: 1px solid rgba(255,119,58,0.5);
    font-weight: 500;
    color: #FF773A;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
.pop-content-subtitle {
    padding-left: 10px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
}
.pop-content-score-box {
    margin-bottom: 20px;
    font-weight: bold;
    color: #FF773A;
    font-size: 18px;
    line-height: 24px;
}
.pop-content-score-total {
    margin-right: 10px;
}
.pop-content-star-box {
    width: 16px;
    height: 16px;
    background: #333333;
    border-radius: 2px;
    margin-right: 4px;
    padding: 2px;
}
.pop-content-star-box:last-child {
    margin-right: 0;
}
.pop-content-star {
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../img/star-bg-deactive.png');
}
.score-active {
    background-image: url('../img/star-bg-active.png');
}
.score-half {
    background-image: url('../img/star-bg-half.png');
}