.header-items {
    width: 110px;
    height: 28px;
    border-radius: 6px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #fff;
}
.header-active {
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(210, 212, 217, 0.22);
    color: #000;
}
.footer-button {
    width: 144px;
    height: 30px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
}
.picker-content {
    background: rgba(223, 223, 223, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}
.time-item-box {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    width: 122px;
    box-sizing: border-box;
    height: 100%;
    height: 42px;
}
.time-item-box-download {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.13);
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    height: 30px;
}
.time-box-error {
	border: 1px solid rgba(255, 115, 52, 0.5);
}
.picker-item {
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4px;
    margin-top: -2px;
}
.date-picker-text {
    font-size: 12px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
}
.date-text-box {
    min-width: 100px;
}
.ant-picker-dropdown-range {
    padding: 0;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    visibility: hidden;
}
.ant-picker-panel-container {
    /* background: #000; */
    /* background: rgba(223, 223, 223, 0.06); */
    background: transparent;
}
.ant-picker {
    background: rgba(255, 255, 255, 0.2);
    border: none;
}
.ant-picker-panel {
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.ant-picker.ant-picker-range {
    width: 100%;
}
.ant-picker-header {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.ant-picker-range .ant-picker-active-bar {
    background: rgba(255, 255, 255, 0.2);
}
.ant-picker-input > input {
    color: #fff;
}
.ant-picker-panel-container .ant-picker-panel-focused {
    border-color: rgba(255, 255, 255, 0.2);
}
.ant-picker-cell-in-view.ant-picker-cell {
    color: #fff;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: rgba(255, 255, 255, 0.19);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #000;
    background: #fff;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(255, 255, 255, 0.19);
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba(255, 255, 255, 0.19);
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panle > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgba(255, 255, 255, 0.4);
}
/* .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::after,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::after,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::after,
.ant-picker-panle > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::after {
    /* background: rgba(255, 255, 255, 0.4); */
/* border-color: #fff; */
/* }  */
/* .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after {
    border-color: #f0f0f0;
} */

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #f0f0f0;
    border-bottom: 1px dashed #f0f0f0;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #f0f0f0;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed #f0f0f0;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #f0f0f0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgba(255, 255, 255, 0.4);
}
.ant-picker-header button {
    color: #fff;
}
.ant-picker-header-view button:hover {
    color: #fff;
    opacity: 0.8;
}
.ant-picker-header > button:hover {
    color: #fff;
    opacity: 0.8;
}
.ant-picker-week-panel-row:hover td {
    color: #000;
}
/* .ant-picker-week-panel-row td {
    color: #fff;
} */
.ant-picker-cell {
    color: rgba(255, 255, 255, 0.19);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #fff;
}
.ant-picker-cell-disabled::before {
    background: transparent;
}
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: transparent;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(255, 255, 255, 0.19);
}
.select-values {
    background: rgba(255, 255, 255, 0.2);
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
    background: rgba(255, 255, 255, 0.2);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    display: none;
}

.ant-picker-quarter-panel tbody tr {
    display: flex;
    flex-direction: column;
}
.ant-picker-quarter-panel tbody tr .ant-picker-cell .ant-picker-cell-inner {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-picker-content th {
    color: #fff;
}